<template>
  <div class="wrapper" v-if="hb">
    <BackgroundMusic :musicInfo="info" :contentLocked="true" />
    <div class="header-wrapper">
      <div class="header-right-btn">
        <Button class="play-all" @click="playFromInd()">
          <Icon class="play-all-icon" :type="canPlay ? 'md-arrow-dropright-circle' : 'ios-pause'" />播放全部
        </Button>
      </div>
    </div>

    <div v-for="(line,ind) in audioLines" :key="ind" class="item-wrapper">
      <div class="left-pno" :style="{color:line.unread?'#aaa':''}">{{ind+1}}.</div>
      <AudioInfoItem
        style="flex:1"
        :line="line"
        :playingLine="playingLine"
        @clickPlay="playAudio(line)"
        @clickPause="pauseAudio"
      />
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import AudioInfoItem from './AudioInfoItem';
import { factory as getAudioLines } from './resolve4audio';
export default {
  components: { AudioInfoItem },
  props: {
    hb: { required: true }
  },
  data() {
    return {
      playingLine: null,
      audioLinesMap: [[]],
      canPlay: true
    };
  },
  computed: {
    audioLines() {
      if (/Verse/.test(this.hb.contentType)) {
        return this.audioLinesMap[0];
      } else {
        return this.audioLinesMap[this.currentIndex];
      }
    },
    info() {
      if (/Verse/.test(this.hb.contentType)) {
        return this.hb.attributeList[0];
      } else {
        return this.hb.attributeList?.[this.currentIndex];
      }
    }
  },
  methods: {
    delAllAudios() {
      this.audioLines.forEach((line) => {
        line.audio = null;
      });
    },

    playAudio(line) {
      return new Promise((resolve) => {
        if (!line.audio) {
          resolve();
          return;
        }
        if (/^blob:/.test(this.$audio.src)) {
          console.log('释放')
          URL.revokeObjectURL(this.$audio.src);
        }
        if (line.file) {
          this.$audio.src = URL.createObjectURL(line.file);
        } else {
          this.$audio.src = line.audio.url;
        }

        this.$audio.play();
        this.canPlay = false;
        this.playingLine = line;
        this.$emit('audioText', line);
        this.$audio.onended = () => {
          this.canPlay = true;
          this.playingLine = null;
          this.$emit('audioText', { text: '', paragraphKey: '' });
          resolve();
        };
      });
    },
    pauseAudio() {
      this.canPlay = true;
      this.$audio.pause();
      this.playingLine = null;
      this.$emit('audioText', { text: '', paragraphKey: '' });
    },
    playFromInd(ind = 0) {
      if (ind >= this.audioLines.length) {
        this.pauseAudio();
        return;
      }
      this.canPlay = false;
      this.playAudio(this.audioLines[ind]).then(() => {
        this.playFromInd(ind + 1);
      });
    }
  },
  beforeMount() {
    this.audioLinesMap = getAudioLines(this.hb);
  }
};
</script>

<style scoped>
.wrapper {
  padding: 0.5em 1em;
  font-size: 14px;
  padding-bottom: 3.5em;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
}
.header-tip {
  width: 38em;
  padding: 0 1em;
}
.play-all {
  background: rgb(146, 101, 5);
  color: white;
}
.play-all-icon {
  font-size: 1.4em;
  margin-right: 0.3em;
}

.item-wrapper {
  display: flex;
}
.left-pno {
  width: 2em;
}
</style>