<template>
  <div v-if="line.unread" style="padding:0 .125em .5em;color:#aaa">
    <div>{{line.text}}</div>
    <div>无配音文本，无需配音文件</div>
  </div>
  <div v-else style="display:flex">
    <div class="middle-cont">
      <Tooltip content="已复制" placement="top" :disabled="hiddenCopyTip" class="txt-div">
        <span
          style="cursor:pointer"
          @click="copyText"
          title="点击复制"
          :class="playingLine!==line?'':'text-success'"
        >{{line.text.split("").filter((char, i) => !line.unreadFlags[i]).join("")}}</span>
      </Tooltip>
      <VoiceSex v-if="line.voiceSex" :disabled="true" v-model="line.voiceSex" style="width:4.5em" />

      <div v-if="line.audio">
        <span class="txt-div text-primary">{{line.audio.name}}</span>
        <span
          style="color:#aaa"
          v-if="line.audio.fileSize"
        >&nbsp;({{line.audio.fileSize | fileSize}})</span>
      </div>
    </div>
    <div class="right-play">
      <div v-if="line.audio">
        <Icon
          v-if="playingLine!==line"
          @click="$emit('clickPlay')"
          title="播放配音"
          class="fr play-ion"
          type="md-arrow-dropright-circle"
        />
        <Icon v-else class="fr play-ion" type="ios-pause" @click="$emit('clickPause')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    line: {},
    playingLine: {}
  },
  data() {
    return { hiddenCopyTip: true };
  },
  methods: {
    delAudio(line) {
      line.audio = null;
    },
    proxyUpload(file) {
      this.line.audio = {
        name: file.name,
        key: ''
      };
      this.line.file = file;
      const url = URL.createObjectURL(file);
      const audioElement = new Audio(url);
      audioElement.addEventListener('loadedmetadata', () => {
        this.line.audio.duration = Math.round(audioElement.duration);
        URL.revokeObjectURL(url);
      });

      return false;
    },
    copyText(e) {
      let input = document.createElement('input');
      input.value = e.target.innerText;

      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.hiddenCopyTip = false;
      setTimeout(() => {
        this.hiddenCopyTip = true;
      }, 1000);
    }
  },
  beforeMount() {},

  watch: {
    'line.unreadFlags': {
      handler() {
        setTimeout(() => {
          this.$forceUpdate();
        }, 16);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.middle-cont {
  width: calc(100% - 10.5em);
  padding-bottom: 1.2em;
}
.right-play {
  width: 4em;
  display: flex;
  align-items: flex-end;
  padding-bottom: 2em;
}
.play-ion,
.upload-ion,
.del-ion {
  cursor: pointer;
}
.play-ion {
  color: rgb(146, 101, 5);
  font-size: 3em;
}
.del-ion {
  color: #9b3333;
  font-size: 1.5em;
}
.upload-ion {
  /* height: 3em; */
  font-size: 2.5em;
  margin-right: 0.35em;
  color: rgb(146, 101, 15);
}
</style>